@use '../../styles/base';

.change-password-form {
  box-shadow: base.$shadow-hover;
  min-width: 600px;
  padding: base.$space-xxlarge base.$space-large;

  &-container {
    
  }
}
