@use '../../styles/base';
.slider-container.slick-slider {
    margin-bottom: base.$space-xxlarge;
  @include base.phone {
    margin-bottom: base.$space-xlarge;
  }
  .slick-dots li button:before {
    font-size: 12px;
    box-shadow: none;
  }
  button.slick-arrow {
    width: auto;
    height: 100%;
    z-index: 999999;
    // background: base.$color-background-transparent;

    img {
      filter: base.$color-svg-filter-white;
    }
    &.slick-prev {
      margin-left: 48px;
      &::before {
        content: unset;
      }
    }
    &.slick-next {
      margin-right: 48px;
      &::before {
        content: unset;
      }
    }
  }
}
