@use '../../styles/base';

.search-form {
  width: 100%;
  &-filter {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: base.$space-large base.$space-medium;
    box-shadow: 0 7px 50px 0 rgba(38, 50, 56, 0.1);

    @include base.phone {
      padding: base.$space-medium base.$space-small;
    }  

    &::after {
      content: "";
      background: url(/images/searchBg.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }

    .row {
      &:not(:last-child) {
        margin-bottom: base.$space-medium;
      }
    }

    .search-form-filter-col-keyword {
      @include base.phone {
        flex: 1;
        max-width: unset;
        padding-right: 0px;
      } 
      .search-button {
        display: flex;
        align-items: center;
      }
    }

    .search-form-filter-col-advanced {
      @include base.phone {
        flex: 0;
        max-width: unset;
        button {
          word-break: keep-all;
        }
      }
    }

    &-checkbox-group {
      @include base.phone {
        &.btn-group {
          display: inline-block;
        }
      }
      &:not(:last-child) {
        margin-bottom: base.$space-small;
      }
      label.btn-outline-primary:not(:disabled):not(.disabled) {
        border-radius: 0.25rem;
        background: base.$color-background-body;
        &:hover {
          color: black;
          background: base.$color-background-body;
        }
        &.active {
          color: white;
          background: base.$color-primary;
        }
        
        &:not(:last-child) {
          margin-right: base.$space-small;
        }
        @include base.phone {
          word-break: keep-all;
          margin-top: base.$space-small;
        }
      }
    }

    form button {
      &,
      &:hover {
        border-width: 0px;
      }
      .svg-icon {
        filter: base.$color-svg-filter-white;
      }
    }
  }
  &-result {
    margin-top: base.$space-large;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

div.search-form-modal {
  .filterLabel {
    display: block;
    margin-top: base.$space-large;
    margin-bottom: base.$space-small;
  }

  .modal-header {
    button.close > span {
      font-size: base.$font-size-xxlarge;
    }
  }

  .modal-footer {
    justify-content: start;
    button:last-child {
      position: absolute;
      right: 12px;
    }
  }
}
