@use '../../styles/base';

div.my-bookmarks-container {
  width: 100%;
  overflow-y: auto;
  div.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
