@use '../base';

.week-grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-bottom: 36px;

  @include base.phone {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0px;
    &.container {
      padding: 0px;
    }
  }

  &-item {
    text-align: center;
    flex: 1;
    margin: 0px 12px;
    color: black;
    // cursor: pointer;
    position: relative;
    border-radius: 6px;
    height: 130px;

    @include base.phone {
      margin-bottom: base.$space-medium;;
      &:first-child {
        grid-column: 1/span 2;
      }
    }

    img {
      border-radius: 6px;
    }

    &-overlay {
      height: 130px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      position: absolute;
      top: 0px;
      color: white;
      padding: 4px;
      display: none;
    }

    &-label {
      position: absolute;
      bottom: -36px;
      width: 100%;
      @include base.phone {
        bottom: 0px;
        display: block;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        margin: 0px;
      }
    }

    &:hover {
      .week-grid-item-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .image-lazyload-wrapper {
      height: unset;
    }

    .image-div {
      border-radius: 6px;
    }
  }
}
