@use '../base';

.instagram-slider {
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    span {
      &.divider {
        flex: 1;
        height: 2px;
        border-radius: 2px;
        background-color: base.$color-meta;
      }

      &.text {
        flex: unset;
        text-transform: uppercase;
        font-size: base.$font-size-xxlarge;
        font-weight: bold;
        margin: base.$space-small base.$space-xxlarge;
        text-align: center;
        @include base.phone {
          margin: base.$space-small;
        }
        a {
          margin-left: base.$space-small;
        }
      }
    }
  }
  &-container {
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    max-height: 550px;
    width: 100%;
    margin-bottom: base.$space-small;
    blockquote:not(:last-child) {
      margin-right: base.$space-medium;
    }
  }
}
