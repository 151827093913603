@use '../../styles/base';

.registration-form {
  box-shadow: base.$shadow-hover;
  min-width: 600px;
  padding: base.$space-xxlarge base.$space-large;

  @include base.phone {
    min-width: unset;
    width: 100%;
  }
  
  &-container {
    
  }
}
