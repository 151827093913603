@use '../base';

.button-grid {
  &-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      margin-top: base.$space-small;
      font-size: base.$font-size-xlarge;
      @include base.phone {
        font-size: base.$font-size-large;
      }
    }
  }
}
