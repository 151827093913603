@use '../base';

.card-grid {
  @include base.phone {
    padding-left: 0px;
    padding-right: 0px;
  }

  &.feature-image-grid {
    margin-bottom: 12px;
    & > .feature-image-grid-row {
      overflow-x: scroll;
      width: 100%;
      display: flex;
    }
    .feature-image-grid-container {
      width: 200px;
      min-width: 200px;
      height: 300px;
      position: relative;
      margin-right: 12px;
      border-radius: 6px;
      overflow: hidden;
      .title {
        position: absolute;
        width: 100%;
        bottom: 0px;
        left: 0px;
        color: white;
        font-weight: 500;
        padding: 8px;
        background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9));
        font-size: 16px;
        line-height: 1.2;
      }
    }
  }
}
