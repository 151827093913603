@use '../base';

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: #fff;
  z-index: 1000;
  border-bottom: 1px solid #eaeaea;
  position: sticky;
  top: 0;
  padding: base.$space-medium base.$space-large;

  @include base.phone {
    padding: base.$space-small base.$space-medium;
  }

  &.homepage {
    // @include base.desktop {
    position: fixed;
    &.at-top {
      background-color: transparent;
      color: white;
      border-bottom: 0px;
      nav a {
        color: white;
      }
      .layout-header-action a {
        &.white-link {
          color: white;
        }
        &.submit-event-link {
          color: white;
          background-color: base.$color-primary;
          font-weight: bold;
        }
      }
      .language-switcher button {
        color: white;
      }
      img.logo {
        filter: base.$color-svg-filter-white;
      }
      .hamburger-menu .open-menu-button img {
        filter: base.$color-svg-filter-white;
      }
    }
    // }
  }

  nav {
    display: none;
    margin-left: base.$space-xxlarge;
    font-size: base.$font-size-medium;
    flex: 1;
    a:not(:last-child) {
      margin-right: base.$space-medium;
    }

    @include base.desktop {
      display: block;
    }
  }

  .layout-header-action {
    display: none;
    font-size: base.$font-size-medium;
    @include base.desktop {
      display: flex;
    }

    align-items: center;
    & > *:not(:last-child) {
      margin-right: base.$space-medium;
    }
    .submit-event-link {
      border: 1px solid base.$color-primary;
      color: base.$color-primary;
      padding: base.$space-xsmall base.$space-small;
      border-radius: 4px;
    }
    .login-link {
      /* background-color: base.$color-primary;
      color: white;
      padding: base.$space-xsmall base.$space-small;
      border-radius: 4px; */
    }
    #postlogin-dropdown {
      border: unset;
      color: black;
      background: white;
      border: unset;
      box-shadow: unset;
      font-size: base.$font-size-medium;
      &:hover,
      &:active,
      &:focus {
        // color: base.$color-primary;
      }
      & + div.dropdown-menu > button {
        font-size: base.$font-size-medium;
      }
    }
    .language-switcher {
      button {
        background-color: transparent;
        border: 0px;
        font-size: base.$font-size-medium;
        text-transform: uppercase;
        &:focus {
          outline: none;
        }
        &:not(:last-child) {
          margin-right: base.$space-small;
        }
      }
    }
  }

  .hamburger-menu {
    @include base.desktop {
      display: none;
    }
    flex: 1;
    display: flex;
    justify-content: flex-end;

    button {
      background: unset;
      border: 0px;
      &.open-menu-button {
        display: flex;
        align-items: center;
        padding-right: 0px;
      }
      &.close-menu-button {
        position: absolute;
        right: base.$space-large;
        top: base.$space-large;
      }
    }
  }
}

.mobile-menu-container {
  // position: fixed;
  // z-index: 1;
  // top: 0px;
  // right: 0px;
  // height: 100%;
  // width: 0px;
  // transition: 0.3s;
  // overflow-x: hidden;
  background: white;
  display: flex;
  flex-direction: column;
  // width: 70vw;
  padding: base.$space-large;
  padding-top: base.$space-xxlarge;

  /* &.show {
    width: 70vw;
    padding: base.$space-large;
    padding-top: base.$space-xxlarge;
  } */

  button {
    background: unset;
    border: 0px;
    &.close-menu-button {
      position: absolute;
      right: base.$space-large;
      top: base.$space-large;
    }
  }
  a {
    margin-bottom: base.$space-medium;
    border-bottom: 1px solid black;
    padding-bottom: 6px;
    font-size: base.$font-size-xlarge;
  }
  .logout-button {
    text-align: left;
    padding-left: 0px;
    font-size: base.$font-size-xlarge;
    font-weight: 300;
  }
  .language-switcher {
    margin-top: base.$space-xlarge;
    button {
      background-color: transparent;
      color: black;
      font-weight: 300;
      border: 0px;
      padding: 0px;
      &:focus {
        outline: none;
      }
      font-size: base.$font-size-xlarge;
    }
  }
  .social-media-icons {
    display: flex;
    margin-top: base.$space-large;
    a {
      border: 0px;
      margin-right: base.$space-medium;
      &.mewe img {
        filter: base.$color-svg-filter-primary;
      }
    }
  }
  .profile-container {
    margin-top: base.$space-xlarge;
    margin-bottom: base.$space-medium;
    color: base.$color-primary;
    font-weight: bold;
  }
}

.layout-notification {
  width: 100%;
  background-color: base.$color-primary;
  color: base.$color-primary-contrast;
  padding: base.$space-small base.$space-large;
}

main {
  min-height: calc(100vh - 55px - 139px);
  @include base.phone {
    min-height: calc(100vh - 44px - 184px);
  }
}

footer {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  width: 100%;
  // position: absolute;
  bottom: 0px;
  padding: base.$space-xlarge;
  border-top: base.$color-primary 4px solid;
  background-color: base.$color-background;
  color: white;
  position: relative;

  .layout-footer-links {
    margin-bottom: base.$space-large;
    @include base.phone {
      display: flex;
      flex-wrap: wrap;
    }
    a {
      &,
      &:hover,
      &:active,
      &:visited {
        font-size: base.$font-size-medium;
        color: white;
        &:not(:last-child) {
          margin-right: base.$space-medium;
        }
      }
    }
  }
  small {
    color: base.$color-font-meta;
  }
  .layout-footer-social-media {
    position: absolute;
    right: base.$space-xlarge;
    top: base.$space-large;
    display: flex;
    align-items: center;
    @include base.phone {
      position: relative;
      top: 0px;
      right: 0px;
      margin-bottom: base.$space-medium;
    }
    a {
      display: inline-block;
      &:not(:first-child) {
        margin-left: base.$space-medium;
      }
    }
  }
  button.go-up-btn {
    color: white;
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.3);
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 990;
    background: base.$color-primary;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    border: base.$color-primary 4px solid;
    .svg-icon {
      filter: base.$color-svg-filter-white;
    }
  }
}

ins.footer-ad {
  @include base.phone {
    width: 320px; 
    height: 100px; 
  }
  @include base.tablet {
    width: 468px; 
    height: 60px; 
  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 728px; 
  height: 90px; 
  margin: 24px auto;
  overflow-x: hidden;
}