@use '../../styles/base';

.forget-password-form {
  padding: base.$space-xxlarge base.$space-large;
  box-shadow: base.$shadow-hover;
  min-width: 600px;

  @include base.phone {
    min-width: unset;
    width: 100%;
  }

  &-container {
    
  }
  
  .register-link {
    background: base.$color-meta2;
    padding: base.$space-medium;
    a {
      &,
      &:hover,
      &:visited,
      &:active {
        color: white;
      }
    }
  }
}
