@use 'base';

$theme-colors: (
  "primary": base.$color-primary,
  "success": base.$color-primary,
  "info": tomato,
  "danger": teal,
);
@import "~bootstrap/scss/bootstrap";

html,
body {
  min-height: 100vh;
  color: black;
  font-family: "Montserrat", "Noto Sans HK", "Noto Sans TC", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  margin: unset;
  /* & > .section.page-section {
      min-height: calc(100vh - 240px);
      @include phone {
          min-height: calc(100vh - 210px);
      }
  } */
  a,
  a:visited,
  a:hover,
  a:focus {
    color: black;
  }

  a:hover {
    text-decoration: none;
  }

  a.button-action,
  button.button-action {
    color: base.$color-primary;
    background-color: white;
    text-decoration: none;
    padding: 2px 6px 2px 6px;
    border: 1px solid base.$color-primary;
    border-radius: 6px;
    &:hover {
      background-color: base.$color-primary;
      color: white;
    }
  }

  & > .page-section {
    padding: 0px;
  }

  .page-title {
    i {
      margin-right: base.$space-small;
    }
  }

  h1 {
    font-size: 32px;
    line-height: 45px;
    font-family: "Montserrat", "Noto Sans HK", "Noto Sans TC", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
  }

  h2 {
    font-size: 28px;
    line-height: 40px;
    font-family: "Montserrat", "Noto Sans HK", "Noto Sans TC", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
  }

  h3 {
    font-size: 24px;
    line-height: 33px;
    font-family: "Montserrat", "Noto Sans HK", "Noto Sans TC", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
  }

  h4 {
    font-size: 20px;
    line-height: 28px;
    font-family: "Montserrat", "Noto Sans HK", "Noto Sans TC", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 1px;
  }

  p {
    font-family: "Montserrat", "Noto Sans HK", "Noto Sans TC", "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  div.placeholder-image {
    background-image: url("/images/placeholder.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  display: block;
}

button.btn-success,
button.btn-primary {
  &,
  &:hover,
  &:active {
    color: white;
  }
}

button.btn-outline-primary {
  &:hover,
  &:active {
    color: white;
  }
}

.wp-content img {
  object-fit: contain;
  &.aligncenter {
    margin: 0 auto;
  }
}

@import "./legacy/buttonGrid.scss";
@import "./legacy/card/normal.scss";
@import "./legacy/card/horizontalCard.scss";
@import "./legacy/card/longCard.scss";
@import "./legacy/cardGrid.scss";
@import "./legacy/carousel.scss";
@import "./legacy/changePasswordForm.scss";
@import "./legacy/companySlider.scss";
@import "./legacy/datePicker.scss";
@import "./legacy/editor.scss";
@import "./legacy/event.scss";
@import "./legacy/eventForm.scss";
@import "./legacy/eventSubmission.scss";
@import "./legacy/fallback.scss";
@import "./legacy/forgetPasswordForm.scss";
@import "./legacy/image.scss";
@import "./legacy/instagramSlider.scss";
@import "./legacy/layout.scss";
@import "./legacy/loginForm.scss";
@import "./legacy/myBookmark.scss";
@import "./legacy/myEvent.scss";
@import "./legacy/parallaxSearch.scss";
@import "./legacy/profile.scss";
@import "./legacy/profileForm.scss";
@import "./legacy/registrationForm.scss";
@import "./legacy/searchForm.scss";
@import "./legacy/section.scss";
@import "./legacy/teaserGrid.scss";
@import "./legacy/weekGrid.scss";