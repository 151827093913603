@use '../../styles/base';

div.profile-container {
  width: 100%;

  .profile-tab-nav {
    @include base.phone {
      margin-bottom: base.$space-medium;

      .nav-item {
        .nav-link.active {
          background: white;
            color: base.$color-primary;
            font-weight: bold;
        }
      }
    }

    
  }

  .profile-tab-content {
    border-left: 1px solid #eaeaea;
  }
}
