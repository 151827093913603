@use '../../base';

.event-horizontal-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
  height: 194px;
  box-shadow: base.$shadow-normal;
  margin-bottom: base.$space-medium;
  flex-direction: row;

  @include base.phone {
    height: 400px;
    flex-direction: column;
  }

  &:hover {
    box-shadow: base.$shadow-hover;
  }
  .card-image {
    width: 45%;
    position: relative;
    & > .image-lazyload-wrapper {
      height: 100%;
    }
    @include base.phone {
      width: 100%;
      height: 50%;
    }
  }
  .card-main {
    height: 100%;
    width: 55%;
    display: flex;
    flex-direction: column;
    padding: base.$space-medium;
    position: relative;

    @include base.phone {
      width: 100%;
      height: 50%;
    }

    .card-main-meta {
      font-size: base.$font-size-small;
      line-height: base.$font-size-medium;
      color: base.$color-meta;
      max-height: 18px;
      overflow: hidden;
      width: 100%;
      .card-main-meta-item {
        margin: 0px base.$space-small;
        display: inline-flex;
        align-items: center;
        &:first-child {
          margin-left: 0px;
        }
        i {
          color: base.$color-primary;
        }
      }
      .bookmark {
        position: absolute;
        bottom: 0px;
        right: 20px;
        background-color: white;
        border-width: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        &:focus {
          outline: none;
        }
        &.active {
          img {
            filter: base.$color-svg-filter-primary;
          }
        }
        &:hover:not(:disabled) {
          border-color: #dfdee1;
        }
        &:disabled {
          img {
            opacity: 0.5;
          }
        }
      }
    }

    .external-event-link {
      position: relative;
      display: flex;
      align-items: flex-end;
      p.card-title {
        margin-right: 4px;
        flex: 1;
      }
      p.card-title + * {
        width: 14px;
        height: 14px;
        margin-bottom: 16px !important;
      }
    }

    p.card-title {
      margin: base.$space-small 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      max-height: 56px;
      position: relative;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 1px;
    }

    .excerpt {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      color: base.$color-font-base;
      font-size: base.$font-size-medium;

      line-height: 24px;
      max-height: 72px;
      margin: unset;
    }

    .card-main-bottom {
      position: absolute;
      bottom: 12px;
    }
  }

  .svg-icon {
    filter: base.$color-svg-filter-primary;
    margin-right: 4px;
  }
  .icon-text {
    margin-left: 4px;
  }
}
