@use '../../styles/base';

div.event-submission-container {
  width: 100%;

  div.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .event-submission-container-item {
    &.active {
      display: block;
    }

    &.inactive {
      display: none;
    }
  }

  .event-submission-action-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: base.$space-medium;

    button:not(:last-child) {
      margin-right: base.$space-medium;
    }
  }

  .user-form-container {
    display: flex;
    flex-direction: row;

    &>div {
      margin: base.$space-large;
      flex: 1;
    }

    .login-form {
      min-width: unset;

      .register-link {
        display: none;
      }

      .forget-password-link {
        display: none;
      }
    }

    .registration-form {
      min-width: unset;
    }
  }

  .event-confirmation {
    min-height: calc(100vh - 55px - 139px);

    @include base.phone {
      min-height: calc(100vh - 44px - 184px);
    }

    button:not(:last-child) {
      margin-right: base.$space-medium;
    }
  }
}