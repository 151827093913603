@use '../../styles/base';

div.editor {
  border: 1px solid #ced4da;
  // min-height: 400px;
  border-radius: 0.3rem;
  padding: base.$space-medium;

  & > div {
    min-height: 400px;
  }

  /* .inlineTooltip {
    display: none;
  } */
}
