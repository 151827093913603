@use '../../base';

.event-long-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  overflow: hidden;
  height: 362px;
  box-shadow: base.$shadow-normal;
  margin-bottom: base.$space-medium;

  &:hover {
    box-shadow: base.$shadow-hover;
  }

  .card-header {
    height: 48%;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 0px;
    border-bottom: none;

    & > .image-lazyload-wrapper {
      height: 100%;
    }

    .price-tag {
      background: base.$color-primary;
      opacity: 0.9;
      border-bottom-right-radius: 12px;
      border-top-right-radius: 12px;
      position: absolute;
      bottom: 16px;
      color: white;
      padding: 0px base.$space-medium;
      font-size: base.$font-size-medium;
    }

    button.simplefavorite-button {
      height: 40px;
      width: 40px;
      position: absolute;
      bottom: -16px;
      right: base.$space-large;
      border-radius: 50%;
      margin-bottom: 0;
      background-color: white;
      border-color: base.$color-primary;
      border: 1px solid #eee;
      padding: 0px;
      color: base.$color-primary;
    }
  }

  .card-main {
    height: 52%;
    display: flex;
    flex-direction: column;
    padding: base.$space-medium;

    .external-event-link {
      position: relative;
      display: flex;
      align-items: flex-end;
      p.card-title {
        margin-right: 4px;
        flex: 1;
      }
      p.card-title + * {
        width: 14px;
        height: 14px;
        margin-bottom: 16px !important;
      }
    }

    p.card-title {
      margin: base.$space-small 0px;
      overflow: hidden;
      max-height: 56px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 1px;
    }

    .excerpt {
      overflow: hidden;
      color: base.$color-font-base;
      font-size: base.$font-size-medium;
      line-height: 24px;
      max-height: 96px;
      margin: unset;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }


  .svg-icon {
    filter: base.$color-svg-filter-primary;
    margin-right: 4px;
  }
}
