@use '../../styles/base';

.event-form {
  box-shadow: base.$shadow-hover;
  width: 100%;
  padding: base.$space-large;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-left: 1px solid base.$color-primary;

  @include base.phone {
    padding: 0px;
  }

  label {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: base.$color-meta;
    font-size: base.$font-size-medium;
  }
  small.form-text {
    color: base.$color-meta2;
    font-size: base.$font-size-small;
  }

  .input-group > .input-group-prepend > .input-group-text {
    background-color: base.$color-primary;
    color: white;
    border-color: base.$color-primary;
  }

  .form-section-title {
    background: base.$color-primary;
    color: white;
    padding: 12px;
    font-size: 24px;
    margin: base.$space-xlarge 0px base.$space-xmedium -72px;
    opacity: 0.95;
    width: 200px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    position: sticky;
    top: 100px;
    z-index: 999;
    &:not(:first-child) {
      background: base.$color-highlight;
    }

    @include base.phone {
      margin-left: -8%;
      padding: 6px;
    }
  }

  .accordion .card-header {
    cursor: pointer;
    color: base.$color-meta;
    img.arrow-down {
      transform: rotate(180deg);
      display: inline-block;
      margin-left: 6px;
    }
  }

  .inline-select {
    margin-left: base.$space-medium;
    display: inline;
    width: auto;
  }

  #event-frequency-setting-section {
    @media (min-width: 768px) {
      margin-top: 24px;
    }
  }

  .event-form-section.image-section {
    margin-top: base.$space-large;
    margin-bottom: base.$space-large;
  }

  .occurence-table {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto;
    border-top: 1px solid base.$color-primary;
    border-left: 1px solid base.$color-primary;
    padding: unset;
    .occurence-table-checkbox {
      margin-right: unset;
      border-bottom: 1px solid base.$color-primary;
      border-right: 1px solid base.$color-primary;
      input {
        position: absolute;
        opacity: 0;
        left: 8px;
        cursor: pointer;
      }
      input:checked ~ label {
        background: base.$color-primary;
        color: white;
      }
      label {
        color: base.$color-primary;
        width: 100%;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
      }
    }
  }

  .upload__image-wrapper {
    border: 2px dashed #ccc;
    border-radius: 6px;
    padding: base.$space-medium;
    width: 100%;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image-item-wrapper {
      // display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      margin: base.$space-small 0px;
    }
    .image-item {
      position: relative;
      padding: base.$space-medium;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .uploaded-image {
        object-fit: contain;
        &.selected {
          border: 4px solid base.$color-primary !important;
        }
      }
      .image-item__btn-wrapper {
        position: relative;
        .checkmark-btn {
          background: unset;
          border: unset;
        }
        .close-btn {
          background: unset;
          border: unset;
        }
      }
    }

    .svg-icon {
      filter: base.$color-svg-filter-primary;
    }
  }

  .react-tagsinput {
    height: auto;
    &--focused {
      border-color: base.$color-primary;
    }
    &-tag {
      background-color: base.$color-primary;
      border-color: base.$color-primary;
      color: white;
      font-size: base.$font-size-small;
      margin-bottom: unset;
      padding: 2px;
    }
    input {
      margin-bottom: unset;
    }
  }

  .invalid-feedback {
    &.submit-button-error {
      display: block;
      cursor: pointer;
      background-color: transparent;
      border: 0px;
      outline: none;
      font-size: base.$font-size-medium;
      &:focus {
        outline: none;
      }
    }
    &.always-shown {
      display: block;
      text-align: center;
    }
    font-size: 150%;
    font-weight: bold;
  }
}
