@use '../base';

.parallax-search {
  position: relative;
  /* Set a specific height */
  height: 600px;
  @include base.phone {
    height: 460px;
  }
  @include base.tablet {
    height: 500px;
  }
  width: 100%;
  display: flex;
  align-items: center;
  background-color: blac1;

  @include base.phone {
    background-attachment: unset;
  }

  .parallax-search-background-image {
    filter: brightness(60%);
  }

  &-container {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.8)
    );
    @include base.phone {
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
      align-items: flex-end;
      padding-bottom: 36px;
    }
  }

  h1 {
    color: white;
  }

  form button {
    &,
    &:hover {
      background-color: base.$color-primary;
      border-width: 0px;
    }
    .svg-icon {
      filter: base.$color-svg-filter-white;
    }
  }

  .quick-filter {
    margin-top: base.$space-medium;
    &-label {
      color: white;
      margin-right: base.$space-small;
      font-size: base.$font-size-medium;
    }
    &-tag {
      display: inline-block;
      border-radius: 8px;
      background: rgba(81, 212, 157, 0.5);
      color: white;
      padding: 4px 8px;
      font-size: base.$font-size-large;
      font-weight: bold;
      margin: 4px;
      &:not(:last-child) {
        margin-right: base.$space-small;
      }
      @include base.phone {
        padding: 2px 4px;
        font-size: 14px;
      }
    }
  }

  .search-button {
    display: flex;
    align-items: center;
  }

  .recommend-event-box {
    // background-color: rgba(0, 0, 0, 0.5);
    margin-bottom: 12px;
    @include base.desktop {
      margin-bottom: 36px;
    }
    border-radius: 6px;
    color: white;

    @include base.phone {
      margin-top: base.$space-medium;
    }

    &-title {
      color: base.$color-primary;
      font-size: base.$font-size-xlarge;
      line-height: 26px;
      max-height: 104px;
      -webkit-line-clamp: 4;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      display: -webkit-box;

      @include base.phone {
        margin-top: base.$space-medium;
        max-height: 52px;
        -webkit-line-clamp: 2;
      }
    }
    &-title-prefix {
      font-size: 1rem;
      line-height: 1.5;
    }
    &-description {
      &::before {
        background-color: white;
        content: "";
        display: block;
        height: 1px;
        margin: 0.2em 0.4em 0.2em 0em;
      }
      font-size: base.$font-size-medium;
      line-height: 20px;
      max-height: 106px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }

    &-event-container {
      position: relative;
      display: flex;
      &-image {
        width: 30%;
        position: relative;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 2%;
      }
      &-detail {
        width: 65%;
      }
    }
  }

  .credit-to {
    position: absolute;
    right: 2px;
    bottom: 2px;
    padding: 2px;
    font-size: 8px;
    color: white;
    z-index: 99;
    opacity: 0.3;
    line-height: 1.8;
    a {
      color: white;
    }
  }
}
