@use '../../styles/base';

div.my-events-container {
  width: 100%;
  overflow-y: auto;
  div.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a.update-link {
    text-decoration: underline;
  }
  table {
    thead {
      th,
      td {
        word-break: keep-all;
      }
    }
  }
}
