$color-primary: #51D49D;
$color-background: #343b42;
$color-background-transparent: rgba(0, 0, 0, 0.50);
$color-background-body: white;
$color-background-lightgrey: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 20%, rgba(248,248,248,1) 80%, rgba(255,255,255,1) 100%);
$color-meta: #6e6e6e;
$color-meta2: #6f7287;
$color-highlight: #3d64ff;
$color-highlight-2: #FF6F69;
$color-primary-contrast: white;
$color-font-base: #333333;
$color-font-base-2: white;
$color-font-meta: #cdcdcd;
$color-dark: black;
$color-input-background: #f8f7fa;
$color-hashtag: #69ba98;

$color-ribbon-alert: #FF6F69;
$color-ribbon-warning: rgba(255, 203, 92, 0.82);
$color-ribbon-reminder: #AAD8B0;
$color-ribbon-expired: #6f7287;
$color-error: #ff0033;

$color-svg-filter-primary: invert(73%) sepia(60%) saturate(382%) hue-rotate(100deg) brightness(90%) contrast(91%);
$color-svg-filter-white: invert(100%) sepia(0%) saturate(0%) hue-rotate(173deg) brightness(105%) contrast(101%);

$space-xsmall: 4px;
$space-small: 6px;
$space-medium: 12px;
$space-xmedium: 18px;
$space-large: 24px;
$space-xlarge: 36px;
$space-xxlarge: 48px;
$space-xxxlarge: 72px;

$tablet-width: 768px;
$desktop-width: 1024px;

$font-size-xsmall: 10px;
$font-size-small: 12px;
$font-size-medium: 14px;
$font-size-large: 16px;
$font-size-xlarge: 18px;
$font-size-xxlarge: 24px;

$shadow-normal: 0 16px 64px -16px rgba(46,55,77,.1);
$shadow-hover: 0 16px 64px -16px rgba(46,55,77,.24);

/* @font-face {
    font-family: 'Futura';
    src: url("../fonts/futura/Futura Book font.ttf");
} */

%flex-wrapper-row {
    display: flex;
    flex-direction: row;
}

%flex-wrapper-row-center {
    @extend %flex-wrapper-row;
    align-items: center;
}

@mixin phone {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}
  
@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

.flex-wrapper {
    @extend %flex-wrapper-row;
    .item-expand {
        flex: 1;
    }
}