@use '../base';

.company-slider {
  width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  & > * {
    display: inline-block;
    width: max-content;

    padding-left: 50%;
    /* show the marquee just outside the paragraph */
    will-change: transform;
    animation: marquee 50s linear infinite;
    &:hover {
      animation-play-state: paused
    }
    &:not(:hover) {
      animation-play-state: running
    }
  }
  &-img {
    display: inline-block;
    padding: 0 2.2rem;
    vertical-align: middle;
    opacity: 0.25;
    outline: none;
    cursor: default;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
    object-fit: contain;
    &:hover {
      opacity: 1;
    }
  }
  &::before, &::after {
    height: 100%;
    width: 180px;
    position: absolute;
    content: "";
    z-index: 2;
    top: 0px;
    @include base.phone {
      width: 80px;
    }
  }
  &::before {
    left: 0px;
    background: linear-gradient(to left, rgba(255,255,255,0.001) 0%, white);
  }
  &::after {
    right: 0px;
    background: linear-gradient(to right, rgba(255,255,255,0.001) 0%, white);
  }
}

@keyframes marquee {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}

/* Respect user preferences about animations */
@media (prefers-reduced-motion: reduce) {
  .company-slider > * {
    animation-iteration-count: 1;
    animation-duration: 0.01; 
    /* instead of animation: none, so an animationend event is 
     * still available, if previously attached.
     */
    width: auto;
    padding-left: 0;
  }
}
