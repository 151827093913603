@use '../base';

.layout-section {
  padding-top: base.$space-large;
  padding-bottom: base.$space-large;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.fluid {
    max-width: unset !important;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.section-title {
  margin-bottom: base.$space-medium;
  display: flex;
  align-items: center;
  flex-direction: column;
  span.separator {
    height: 4px;
    width: 48px;
    background-color: base.$color-primary;
    margin: 0 auto;
    border-radius: 6px;
  }
}
