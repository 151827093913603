@use '../base';

.background-image {
  object-fit: cover;
}

.image-placeholder {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image.clickable {
  cursor: pointer;
}
.image-popup {
  .modal-content {
    background: transparent;
    border: unset;
    .modal-header {
      border: unset;
      button {
        color: white;
        font-size: 48px;
      }
    }
    .modal-body {
      margin: auto;
      min-width: 100%;
      min-height: 80vh;
    }
  }
}