@use '../../base';

.event-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  // overflow: hidden;
  height: 362px;
  box-shadow: base.$shadow-normal;
  margin-bottom: base.$space-xmedium;

  &:hover {
    box-shadow: base.$shadow-hover;
  }

  .card-header {
    height: 55%;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 0px;
    border-bottom: none;

    & > .image-lazyload-wrapper {
      height: 100%;
    }

    .price-tag {
      background: base.$color-primary;
      opacity: 0.9;
      border-bottom-right-radius: 12px;
      border-top-right-radius: 12px;
      position: absolute;
      bottom: 16px;
      color: white;
      padding: 0px base.$space-medium;
      font-size: base.$font-size-medium;
    }

    .bookmark {
      position: absolute;
      bottom: -12px;
      right: 12px;
      padding: 4px;
      background-color: white;
      border-radius: 50%;
      border-style: solid;
      border-width: 1px;
      border-color: base.$color-input-background;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      &:focus {
        outline: none;
      }
      &.active {
        img {
          filter: base.$color-svg-filter-primary;
        }
      }
      &:hover:not(:disabled) {
        border-color: #dfdee1;
      }
      &:disabled {
        img {
          opacity: 0.5;
        }
      }
    }

    button.simplefavorite-button {
      height: 40px;
      width: 40px;
      position: absolute;
      bottom: -16px;
      right: base.$space-large;
      border-radius: 50%;
      margin-bottom: 0;
      background-color: white;
      border-color: base.$color-primary;
      border: 1px solid #eee;
      padding: 0px;
      color: base.$color-primary;
    }
  }

  .card-main {
    height: 45%;
    display: flex;
    flex-direction: column;
    padding: base.$space-medium;

    .card-main-meta {
      font-size: base.$font-size-small;
      color: base.$color-meta;
      max-height: 18px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      white-space: nowrap;

      > span:last-child {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      > img:not(:first-child) {
        margin-left: base.$space-small;
      }

      .card-main-meta-item {
        margin: 0px base.$space-small;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        &:not(:first-child) > span {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          flex: 1;
          width: 100%;
        }

        &:first-child {
          margin-left: 0px;
          flex: unset;
          > span {
            white-space: nowrap;
          }
        }
        i {
          color: base.$color-primary;
        }
      }
    }

    .external-event-link {
      position: relative;
      display: flex;
      align-items: flex-end;
      p.card-title {
        margin-right: 4px;
        flex: 1;
      }
      p.card-title + * {
        width: 14px;
        height: 14px;
        margin-bottom: 16px !important;
      }
    }

    p.card-title {
      margin: base.$space-small 0px;
      overflow: hidden;
      max-height: 84px;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 1px;
    }

    .card-main-bottom {
      position: absolute;
      bottom: 12px;
    }
  }

  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    &::before {
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid base.$color-ribbon-reminder;
      opacity: 0.5;
    }
    &::after {
      position: absolute;
      z-index: -1;
      content: "";
      display: block;
      border: 5px solid base.$color-ribbon-reminder;
      opacity: 0.5;
    }
    &.ribbon-top-right {
      top: -9px;
      right: -10px;
      &::before {
        border-top-color: transparent;
        border-right-color: transparent;
        top: 0;
        left: 16px;
      }
      &::after {
        border-top-color: transparent;
        border-right-color: transparent;
        bottom: 16px;
        right: 0;
      }
      span {
        left: -25px;
        top: 30px;
        transform: rotate(45deg);
        padding-left: 24px;
      }
    }
    span {
      position: absolute;
      display: block;
      width: 225px;
      padding: base.$space-xsmall 0;
      background-color: base.$color-ribbon-reminder;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      color: base.$color-primary-contrast;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;
    }
    &.ribbon-urgent {
      span {
        background-color: base.$color-ribbon-alert;
      }
      &::before {
        border-color: base.$color-ribbon-alert;
      }
      &::after {
        border-color: base.$color-ribbon-alert;
      }
    }
    &.ribbon-high {
      span {
        background-color: base.$color-ribbon-warning;
      }
      &::before {
        border-color: base.$color-ribbon-warning;
      }
      &::after {
        border-color: base.$color-ribbon-warning;
      }
    }
    &.ribbon-expired {
      span {
        background-color: base.$color-ribbon-expired;
      }
      &::before {
        border-color: base.$color-ribbon-expired;
      }
      &::after {
        border-color: base.$color-ribbon-expired;
      }
    }
  }

  .svg-icon {
    filter: base.$color-svg-filter-primary;
    display: inline-block;
    margin-right: 4px;
  }
  .icon-text {
    margin-left: 4px;
  }
}
