@use '../base';

.teaser-grid {
  &-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      margin-top: base.$space-medium;
      font-size: 20px;
      line-height: 28px;
      letter-spacing: 1px;
    }
    p {
      margin-top: base.$space-small;
    }
  }
}
