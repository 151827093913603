@use '../../styles/base';

#eventPage {
  .advertisement.image {
    object-fit: contain;
  }

  .box.recently-viewed-events {
    box-shadow: unset;
    padding: 0px;
    padding-bottom: base.$space-xmedium;
    .box-item-title {
      border-left: 0px;
      margin: 0 auto base.$space-medium auto;
    }

    .slider-continaer {
      width: 100%;
      .slick-dots li button:before {
        font-size: 24px;
        box-shadow: none;
      }
      .event-horizontal-card {
        margin: base.$space-small;
      }
    }
  }

  .box.event-you-may-like {
    box-shadow: unset;
    .box-item-title {
      border-left: 0px;
      margin: 0 auto base.$space-medium auto;
    }
    .box-item-action {
      position: absolute;
      right: base.$space-medium;
      a {
        display: flex;
        align-items: center;
        img {
          margin-left: base.$space-small;
          filter: base.$color-svg-filter-primary;
          transform: rotate(90deg);
        }
      }
    }
  }
}

.event-page.header {
  .container {
    display: flex;
    align-items: flex-start;
  }

  @include base.phone {
    .event-meta {
      flex-direction: column;
      align-items: start;
      .tags {
        margin-bottom: base.$space-medium;
        span {
          display: inline-block;
        }
      }
    }
  }
}

.event-page.box {
  margin-top: base.$space-medium;
  margin-bottom: base.$space-medium;
  &:first-child {
    margin-top: 0px;
  }
  .icon-text {
    display: flex;
    align-items: center;
    color: base.$color-meta;
    img {
      margin-right: base.$space-small;
      filter: base.$color-svg-filter-primary;
    }
  }
  .events-btn-group {
    & > div:not(:last-child) {
      margin-right: base.$space-medium;
    }
  }
  .cta-btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: base.$font-size-small;
    &:not(:last-child) {
      margin-bottom: base.$space-small;
    }
    a {
      width: 100%;
      background: base.$color-primary-contrast;
      color: base.$color-primary;
      text-align: center;
      padding: base.$space-xsmall base.$space-small;
      border-radius: 3px;
      border: 1px solid base.$color-primary;
      font-size: base.$font-size-large;
      &:hover {
        background: base.$color-primary;
        color: base.$color-primary-contrast;
      }
      &.disabled {
        pointer-events: none;
        cursor: default;
        background: base.$color-meta;
        border-color: base.$color-meta;
        opacity: 0.3;
        &:hover {
          background: base.$color-meta;
          border-color: base.$color-meta;
        }
      }
    }
    &.highlight {
      a {
        background: base.$color-highlight;
        color: base.$color-primary-contrast;
        border: 1px solid base.$color-highlight;
        &:hover {
          background: base.$color-highlight;
          color: base.$color-primary-contrast;
        }
      }
    }
  }
  .add-to-calendar button {
    background: unset;
    padding: unset;
    &,
    &:visited,
    &:hover,
    &:focus {
      border: none;
    }
  }
  .bookmark button.simplefavorite-button {
    border: none !important;
    padding: unset;
    height: unset;
    width: unset;
  }
}

.event_header {
  .single_bannerimage_group {
    position: relative;
    width: 100%;

    & > div:first-child {
      position: absolute !important;
      z-index: 1;
    }

    .single-banner-image {
      position: relative;
      width: 100%;

      &.blurred {
        object-fit: fill;
        filter: blur(20px);
      }

      &.unblurred {
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }
  .listing-gallery-wrap {
    button.slick-prev,
    button.slick-next {
      color: base.$color-primary;
      width: 40px;
      height: 40px;
      line-height: 40px;
      @include base.phone {
        top: 45%;
        width: 32px;
        height: 32px;
        line-height: 32px;
      }
    }
    button.slick-prev {
      left: base.$space-xmedium;
    }
    button.slick-next {
      right: base.$space-xmedium;
    }
    .listing-gallery-holder {
      margin: 0px base.$space-xsmall;
      background-position: center;
      @include base.phone {
        max-height: 265px;
      }
    }
    div.slick-track {
      a.slick-slide:not(.slick-current) {
        .listing-gallery-holder {
          opacity: 0.8;
        }
      }
    }
  }
}

.event_body {
  display: flex;
  flex-direction: row;
  .event_body_main {
    flex: 1;
  }
  .event_body_side {
    width: 30%;
  }
  background: white;
  padding: 0px;

  hr {
    width: 100%;
  }

  .organizer {
    .organizer-name {
      font-weight: bold;
    }
  }
  .disclaimer {
    min-height: 300px;
    position: relative;
    & > div {
      position: relative;
    }
    .blocked {
      background: rgba(248, 248, 248, 0.95);
      width: calc(100% - 36px);
      text-align: center;
      height: calc(100% - 78px);
      padding: 0px base.$space-medium;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .ad {
    background-size: cover;
    width: 728px;
    height: 90px;
    background-repeat: no-repeat;
    margin: auto;
    max-width: 100%;
  }
}

.event-page.listing-content-footer.section {
  padding: 0px;
  .section-title {
    padding: 12px 0px;
    color: base.$color-font-base;
    font-size: base.$font-size-large;
    margin-bottom: base.$space-xmedium;
    i {
      color: base.$color-primary;
      margin-left: base.$space-small;
    }
  }
  .previous-next-post-section {
    margin: 5px 0px;
    padding: 15px;
    border-radius: 15px;
    .section-title {
      @include base.phone {
        & > div {
          text-align: left;
        }
      }
    }
    .row > div:last-child {
      @include base.phone {
        display: none;
      }
      @include base.tablet {
        display: none;
      }
    }
  }
  .next-post-link {
    float: right;
  }
  .comments-area {
    margin-top: 10px;
    border-radius: 15px;
  }
}

.event-page.box {
  box-shadow: base.$shadow-hover;
  padding: base.$space-xmedium;
  color: base.$color-meta;

  .box-item {
    @extend %flex-wrapper-row-center;
    font-size: base.$font-size-medium;
    &.column {
      flex-direction: column;
    }
    &.start {
      align-items: flex-start;
    }
    &:not(:last-child) {
      margin-bottom: base.$space-small;
    }
    &.collapse-section {
      .collapse-section-item {
        cursor: pointer;
        width: 100%;
      }
    }
    &.collapse-section-show {
      .collapse-section-item {
        background: transparent;
        opacity: 1;
      }
    }
    &.collapse-section-hide {
      .collapse-section-item {
        background: base.$color-meta;
        opacity: 0.3;
      }
    }
    h3 {
      margin: 0px;
      font-weight: bold;
      color: black;
    }
    i {
      color: base.$color-primary;
      font-size: base.$font-size-xxlarge;
    }
    span.glyphicon {
      color: base.$color-primary;
      font-size: base.$font-size-xxlarge;
    }
    table {
      margin-bottom: base.$space-small;
      i {
        color: base.$color-font-base;
        font-size: base.$font-size-medium;
      }
      a,
      a:visited,
      a:hover,
      a:focus {
        color: base.$color-primary;
      }
    }
  }
  
  .box-item-title {
    border-left: 4px solid base.$color-primary;
    padding-left: base.$space-small;
    font-size: base.$font-size-xlarge;
    margin-bottom: base.$space-xmedium;
    color: base.$color-font-base;
  }
}
.event-page.box.header {
  background: white;
  width: 100%;

  .events-btn-group {
    align-items: center;
  }

  .tags .label {
    background-color: base.$color-primary;
    color: base.$color-primary-contrast;
    padding: 0.2em 0.6em 0.3em;
    margin-right: base.$space-xsmall;
    margin-bottom: base.$space-xsmall;
    font-size: base.$font-size-small;
    display: inline-block;
    border-radius: 0.25em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    a,
    a:visited,
    a:hover,
    a:focus {
      color: base.$color-primary-contrast;
    }
  }
}

.event-page.box.content {
  overflow-x: scroll;
  .box-item-content {
    width: 100%;
    &, p {
      font-size: 16px;
      line-height: 26px;
      font-family: Merriweather, serif;
      color: black;
    }
    img {
      height: auto;
      object-fit: contain;
    }
    i {
      color: inherit;
      font-size: inherit;
    }
  }
  .addtInfoTable {
    width: 100%;
    padding: 0px base.$space-medium;
  }
  table {
    overflow-x: scroll;
    margin-top: base.$space-medium;
    width: 100%;
    th {
      width: 130px;
      @include base.phone {
        width: 80px;
      }
    }
    td {
      word-break: break-word;
    }
  }
  .desc {
    word-break: break-word;
  }
}

.event-page.box.disclaimer {
  a,
  a:visited,
  a:hover,
  a:focus {
    color: base.$color-primary;
  }
}

.event-page.box.hashtag {
  box-shadow: none;
  a.hashtag {
    background-color: base.$color-hashtag;
    margin-right: base.$space-xsmall;
    margin-bottom: base.$space-xsmall;
    color: base.$color-primary-contrast;
    font-size: base.$font-size-medium;
    padding: base.$space-xsmall;
    display: inline-block;
  }
}

.event-page.box.venue {
  .map {
    width: 100%;
    height: 200px;
  }
  .map-link {
    width: 100%;
    color: base.$color-font-base;
    text-align: center;
    font-size: base.$font-size-small;
    margin-top: base.$space-xsmall;
    text-decoration: underline;
  }
  table td p {
    margin-bottom: unset;
  }
}

.event-page.box.more,
.event-page.box.organizer,
.event-page.box.info,
.event-page.box.venue {
  table {
    th,
    td {
      border: 0px;
      padding: 0px;
      text-align: left;
      line-height: unset;
      font-weight: normal;
      vertical-align: top;
    }
    th {
      width: 80px;
    }
    td {
      padding-left: base.$space-xsmall;
      word-break: break-word;
    }
  }
  .collapse-section-item {
    word-break: break-word;
  }
}

.event-page.box.organizer {
  .organizer-name {
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: base.$space-small;
  }

  .organizer-desc {
    margin-top: base.$space-small;
    margin-bottom: base.$space-medium;
  }

  table {
    margin-top: base.$space-small;
    th {
      width: 24px;
    }
  }
  .organizer-image {
    object-fit: contain;
  }
}

.event-page.box.hidden-box {
  display: none;
}

.event-page.box.social-media {
  .social-media-container {
    display: flex;
    overflow-x: scroll;
    max-width: 100%;
    & > *:not(:last-child) {
      margin-right: base.$space-medium;
    }
  }
}

.event-page.box.action {
  a,
  button {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: base.$space-small;
    }
  }
}
